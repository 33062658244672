/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal, type Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { GeodataService, type LocationsModelResource } from '@big-direkt/search';
import { ServiceToolBaseComponent, ServiceToolFieldTrackingDirective } from '@big-direkt/service-tools/base';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { UiCardHeadlineTextLinkComponent, type UiCardHeadlineTextLinkModel } from '@big-direkt/ui/card';
import { UiErrorComponent } from '@big-direkt/ui/error';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { UiAutocompleteInputComponent, UiInputComponent, type AutocompleteOption } from '@big-direkt/ui/input';
import { PaginationService, UiPaginationComponent } from '@big-direkt/ui/pagination';
import { UiSelectComponent, type SelectOption } from '@big-direkt/ui/select';
import { ScrollService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoDirective } from '@jsverse/transloco';
import { Subject, debounceTime, switchMap, type Observable } from 'rxjs';
import { type HomeopathySearchForm } from '../models/homeopathy-search-form.model';
import { type MedicalSubset } from '../models/hoomeopathy-medical-fields.model';
import { HomeopathyDoctorSearchService } from '../services/homeopathy-doctor-search.service';

@Component({
    selector: 'big-service-tools-homeopathy-doctor-search',
    standalone: true,
    imports: [
        AsyncPipe,
        FormsModule,
        ReactiveFormsModule,
        ServiceToolFieldTrackingDirective,
        TranslocoDirective,
        UiButtonComponent,
        UiErrorComponent,
        UiInputComponent,
        UiFormRowComponent,
        UiSelectComponent,
        UiAutocompleteInputComponent,
        UiCardHeadlineTextLinkComponent,
        UiPaginationComponent,
    ],
    templateUrl: './homeopathy-doctor-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        provideTranslationScope('stHomeopathyDoctorSearch', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`)),
    ],
})
export class HomeopathyDoctorSearchComponent extends ServiceToolBaseComponent<HomeopathySearchForm> {
    public readonly elementsPerPage: number = 10;
    private readonly loadLocationsSubject$ = new Subject<string | null | undefined>();

    private readonly homeopathyDoctorSearchService = inject(HomeopathyDoctorSearchService);
    private readonly geodataService = inject(GeodataService);
    private readonly paginationService = inject(PaginationService);
    private readonly scrollService = inject(ScrollService);

    public readonly autocompleteErrorOverrides = { required: 'stHomeopathyDoctorSearch.error.autocompleteRequired' };

    private readonly defaultDebounceTimerMs = 500;
    private readonly defaultSearchRangeValue = 10;

    public override hasBeenSubmitted = false;
    public showResults = false;
    public required = true;

    public locationOptions: Signal<AutocompleteOption<LocationsModelResource>[] | undefined>;
    public medicalFields = signal<SelectOption<string>[]>([]);
    public technicalTerms = signal<SelectOption<string>[]>([]);
    public resultCount = signal<number>(0);
    public availableMedicalSubsets: MedicalSubset[] = [];
    public cardData = signal<UiCardHeadlineTextLinkModel[]>([]);
    public currentPageCount!: Observable<number>;

    public searchRangeOptions: SelectOption<number | undefined>[] = [
        { value: this.defaultSearchRangeValue, key: 'label.closeBySearchRadius', scope: 'stHomeopathyDoctorSearch' },
        { value: 50, key: 'label.averageSearchRadius', scope: 'stHomeopathyDoctorSearch' },
        { value: 9999, key: 'label.maxSearchRadius', scope: 'stHomeopathyDoctorSearch' },
    ];

    public constructor() {
        super();
        /* eslint-disable @typescript-eslint/unbound-method */
        this.form = this.formBuilder.group({
            postcodeOrCity: new FormControl<LocationsModelResource | null | undefined>(undefined, [Validators.required]),
            searchRange: this.formBuilder.nonNullable.control<number>(this.defaultSearchRangeValue),
            medicalInformation: this.formBuilder.group({
                medicalField: new FormControl<string | null>(''),
                technicalTerm: new FormControl<string | null>({ value: '', disabled: true }),
            }),
            homeopathName: new FormControl<string | null | undefined>(undefined),
        });

        this.currentPageCount = this.paginationService.getPage();

        this.locationOptions = toSignal(
            this.loadLocationsSubject$.pipe(
                debounceTime(this.defaultDebounceTimerMs),
                switchMap(async queryParams => {
                    if (!queryParams) {
                        return [];
                    }

                    const locations = await this.geodataService.getLocations(queryParams);

                    return HomeopathyDoctorSearchComponent.mapLocationsToOptions(locations);
                }),
            ),
        );

        // eslint-disable-next-line sonarjs/no-async-constructor
        void this.initMedicalFields();
    }

    public async initMedicalFields(): Promise<void> {
        const medicalInformation = await this.homeopathyDoctorSearchService.getMedicalFields();
        this.medicalFields.set(HomeopathyDoctorSearchComponent.transformToTranslatedSelectOption(medicalInformation));
    }

    public async onMedicalFieldChange(selectedValue: string | undefined): Promise<void> {
        this.resetTechnicalTermSelect(selectedValue);
        if (!selectedValue) {
            return;
        }

        const technicalTerm = await this.homeopathyDoctorSearchService.getTechnicalTerms(selectedValue);
        this.technicalTerms.set(HomeopathyDoctorSearchComponent.transformToTranslatedSelectOption(technicalTerm));
    }

    public async submit(event: Event): Promise<void> {
        event.preventDefault();
        this.hasBeenSubmitted = true;

        if (!this.form?.valid) {
            this.trackStFormSubmitEvent();
            this.showResults = false;

            return;
        }

        this.showResults = true;
        this.paginationService.resetPage({ scrollOnNavigation: false, scrollOnPagination: false });
        await this.loadResults();
        this.trackStFormSubmitEvent(this.resultCount());
    }

    public async onPageChange(pageCount: number): Promise<void> {
        this.paginationService.setPage(pageCount, { scrollOnNavigation: false, scrollOnPagination: false });
        await this.loadResults(pageCount);
    }

    public onAutocompleteInputChange(queryString: string | null | undefined): void {
        this.loadLocationsSubject$.next(queryString);
    }

    public transformOptionValue(option: AutocompleteOption<LocationsModelResource>): string {
        return option.label;
    }

    private resetTechnicalTermSelect(selectedValue: string | undefined): void {
        const technicalTermControl = this.form!.controls.medicalInformation.controls.technicalTerm;
        technicalTermControl.setValue('');

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        selectedValue ? technicalTermControl.enable() : technicalTermControl.disable();
    }

    private async loadResults(currentPage = 1): Promise<void> {
        const selectedAddress = this.form!.controls.postcodeOrCity.value;

        if (!selectedAddress) {
            return;
        }

        const response = await this.homeopathyDoctorSearchService.getResults({
            queryString: this.form!.controls.homeopathName.value ?? '',
            medicalField: this.form!.controls.medicalInformation.controls.medicalField.value ?? undefined,
            technicalTerm: this.form!.controls.medicalInformation.controls.technicalTerm.value ?? undefined,
            geodistanceParams: {
                geo: selectedAddress.geo,
                radius: this.form!.controls.searchRange.value,
            },
            page: currentPage,
        });

        this.cardData.set(
            response.doctors.map(doctor => ({
                title: `${doctor.firstname} ${doctor.lastname}`,
                text: `${doctor.street}<br>${doctor.zip}, ${doctor.city}<br>Telefon: ${doctor.phone}`,
            })),
        );

        this.resultCount.set(response.total);
        this.scrollService.scroll('#resultsHeader');
    }

    private static transformToTranslatedSelectOption(medicalTerms: string[]): SelectOption<string>[] {
        return medicalTerms.map(medicalTerm => ({
            value: medicalTerm,
            key: medicalTerm,
        }));
    }

    private static mapLocationsToOptions(locations: LocationsModelResource[]): AutocompleteOption<LocationsModelResource>[] {
        return locations.map(location => ({
            key: `${location.zipcode}, ${location.city}, ${location.district}`,
            label: `${location.zipcode}, ${location.city}, ${location.district}`,
            value: location,
        }));
    }
}

export default HomeopathyDoctorSearchComponent;
